import { transparentize } from 'polished';
import styled from 'styled-components';
import { colors } from '../../styles/vars/colors.style';
import { font } from '../../styles/vars/font.style';
import PanelBackground from './images/panel-background.svg';
import AudioPlayIcon from './images/audio-play-icon.svg';
import AudioPauseIcon from './images/audio-pause-icon.svg';
import AudioLoadingIcon from './images/audio-loading-icon.svg';
import CloseBtnIcon from './images/close-btn.svg';
import PreviousBtnIcon from './images/previous-btn.svg';
import NextBtnIcon from './images/next-btn.svg';
import AudioOnIcon from './images/audio-on.svg';
import AudioOffIcon from './images/audio-off.svg';
import { mq } from '../../styles/vars/media-queries.style';
import { easings } from '../../styles/vars/easings.style';

export const MapMain = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;

  .map-label {
    padding: 0.6rem;
    pointer-events: none;
    font-family: ${font.primary};
    font-size: min(0.8600469116vw, 1.1rem);
    font-weight: ${font.weight.bold};
    letter-spacing: -0.01em;
    text-transform: uppercase;
    border: 0.1rem solid rgba(255, 248, 243, 0.2);
    border-radius: 0.6rem;
    will-change: transform;
  }
`;

export const MapCanvas = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
`;

export const MapLoading = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: grid;
  place-items: center;
  color: ${colors.light};
`;

export const MapPanel = styled.div`
  position: absolute;
  bottom: 16rem;
  right: calc(50% - 15.4rem);
  width: 30.8rem;
  max-height: calc(80vh - 20rem);
  padding: 3rem;
  z-index: 100;
  pointer-events: ${({ open }) => (open ? undefined : 'none')};
  color: ${colors.light};
  background-color: ${colors.black2};
  border: 0.1rem solid ${transparentize(0.8, colors.light)};
  border-radius: 1rem;
  opacity: 0;
  overflow-x: hidden;
  overflow-y: scroll;

  ${mq.tabletP} {
    bottom: 21rem;
    max-height: calc(50vh - 13rem);
  }

  ${mq.desk} {
    right: 4rem;
    bottom: 6rem;
    max-height: none;
    overflow-y: hidden;
  }
`;

export const NavUI = styled.div`
  position: absolute;
  bottom: 3rem;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${mq.tabletP} {
    bottom: 10rem;
  }

  ${mq.desk} {
    top: calc(50% + 16rem);
    bottom: auto;
  }
`;

export const BackToMapBtn = styled.button`
  margin-top: 2rem;
  margin-left: 3.8rem;
  margin-right: 3.8rem;
  padding-top: 6.4rem;
  font-size: 1.4rem;
  font-weight: ${font.weight.bold};
  line-height: 1.1;
  letter-spacing: 0.01em;
  text-align: center;
  text-transform: uppercase;
  color: ${colors.light};
  white-space: nowrap;
  border: none;
  background-color: transparent;
  background-image: url(${CloseBtnIcon});
  background-repeat: no-repeat;
  background-position: center top;

  &:focus {
    outline: none;
  }
`;

export const PreviousParticleBtn = styled.button`
  display: block;
  width: 5.6rem;
  height: 5.6rem;
  white-space: nowrap;
  text-indent: 100%;
  overflow: hidden;
  background-image: url(${PreviousBtnIcon});
  background-repeat: no-repeat;
  background-color: transparent;
  border: none;

  &:focus {
    outline: none;
  }
`;

export const NextParticleBtn = styled.button`
  display: block;
  width: 5.6rem;
  height: 5.6rem;
  white-space: nowrap;
  text-indent: 100%;
  overflow: hidden;
  background-image: url(${NextBtnIcon});
  background-repeat: no-repeat;
  background-color: transparent;
  border: none;

  &:focus {
    outline: none;
  }
`;

export const DataImage = styled.img`
  display: block;
  margin: 0 auto;
  opacity: ${({ loaded }) => (loaded ? 1 : 0.2)};
`;

export const MapPanelAudio = styled.div`
  position: relative;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url(${PanelBackground});

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: ${(152 / 239) * 100}%;
  }
`;

export const MapPanelWaveform = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const MapPanelHeading = styled.h4`
  font-weight: ${font.weight.bold};
  font-size: 1.4rem;
  line-height: 1.36;
  letter-spacing: -0.01em;
  text-transform: uppercase;
`;

export const MapAudioBtn = styled.button`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 5.6rem;
  height: 5.6rem;
  overflow: hidden;
  color: ${colors.light};
  white-space: nowrap;
  text-indent: 100%;
  border-radius: 50%;
  border: 0.1rem solid ${colors.light};
  background-color: ${colors.black2};
  background-repeat: no-repeat;
  background-position: center;

  ${({ playing }) => {
    if (playing === null) {
      return `background-image: url(${AudioLoadingIcon});`;
    } else {
      return playing
        ? `background-image: url(${AudioPauseIcon});`
        : `background-image: url(${AudioPlayIcon});`;
    }
  }}
`;

export const MapLabel = styled.p`
  position: relative;
  padding: 0.6rem;
  pointer-events: none;
  overflow: hidden;
  z-index: ${({ interactive }) => (interactive ? undefined : '0 !important')};
  font-family: ${font.primary};
  font-size: min(0.8600469116vw, 1.1rem);
  font-weight: ${font.weight.bold};
  color: ${({ color }) => color};
  text-transform: uppercase;
  border-radius: 0.6rem;
  background-color: ${transparentize(0.4, colors.humeBlack700)};
  will-change: transform;

  &:after {
    content: ${({ text }) => `'${text}'`};
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    padding: 0.6rem;
    color: ${transparentize(0.6, colors.light)};
    will-change: transform;
  }
`;

export const ParticleContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -25rem);
  text-align: center;
  opacity: 0;

  ${mq.tabletL} {
    opacity: 1;
  }

  ${mq.desk} {
    top: 50%;
    left: calc(50% - 7vw);
    transform: translate(-100%, -50%);
    text-align: left;
  }
`;

export const ParticleContentMask = styled.span`
  display: block;
  transform: translateY(100%);
`;

export const ParticleIndexMask = styled.p`
  color: ${colors.light};
  overflow: hidden;
`;

export const ParticleIndex = styled.span`
  display: block;
  pointer-events: none;
  will-change: transform;

  span {
    display: block;
  }
`;

export const ParticleTitleMask = styled.p`
  color: ${colors.light};
  overflow: hidden;
`;

export const ParticleTitle = styled.span`
  display: block;
  pointer-events: none;
  will-change: transform;

  span {
    position: relative;
    display: block;
    max-width: 9em;
    padding-bottom: 0.2em;
  }
`;

export const ParticleBorder = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  width: 29.4rem;
  height: 29.4rem;
  border-radius: 50%;
  border: 0.1rem solid ${transparentize(0.8, colors.light)};
`;

export const AudioToggleButton = styled.button`
  position: absolute;
  left: 5.6rem;
  top: 5.6rem;
  width: 5.6rem;
  height: 5.6rem;
  padding: 0;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  z-index: 100;
  text-indent: 100%;
  border-radius: 50%;
  border: 0.1rem solid ${colors.light};
  background-color: ${colors.black2};
`;

export const AudioToggleIcon = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: ${({ active }) =>
    active ? `url(${AudioOnIcon})` : `url(${AudioOffIcon})`};
  background-size: 2.6rem;
  background-repeat: no-repeat;
  background-position: center;
`;

export const MapPrompt = styled.span`
  bottom: 6.7rem;
  color: ${colors.light};
  font-size: 1.6rem;
  display: none;
  left: 9rem;
  pointer-events: none;
  position: absolute;

  > p {
    font-size: 1.6rem;
  }

  &:before {
    background-color: #ffb5d6;
    border-radius: 50%;
    content: '';
    height: 0.8rem;
    overflow: hidden;
    position: absolute;
    left: -1.6rem;
    top: 50%;
    transform: translateY(-50%);
    width: 0.8rem;
  }

  ${mq.tabletL} {
    display: inline-block;
    opacity: ${({ activeParticle }) => (activeParticle ? 0 : 1)};
    transition: opacity 0.4s ${easings.text.in};
  }
`;

export const ExploreWrapper = styled.div`
  position: absolute;
  z-index: 50;
  left: 50%;
  transform: translateX(-50%);
  width: fit-content;
  bottom: 50px;

  ${mq.tabletP} {
    bottom: 10rem;
  }

  ${mq.desk} {
    display: none;
  }
`;
