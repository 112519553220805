import React from 'react';
import { graphql } from 'gatsby';
import Solution from '../components/Solution';
import SEO from '../components/SEO';

const SolutionTemplate = ({ data }) => (
  <>
    <SEO
      title={data.contentfulSolution.title}
      description={data.contentfulSolution.metaDescription.metaDescription}
    />
    <Solution data={data.contentfulSolution} />
  </>
);

export default SolutionTemplate;

export const solutionQuery = graphql`
  query PageBySlug($slug: String!) {
    contentfulSolution(slug: { eq: $slug }) {
      slug
      title
      metaDescription {
        metaDescription
      }
      modalities
      samples
      participants
      emotions
      nationalities
      emotionRatings
      relevantPublications {
        title
        url
      }
      data {
        file {
          url
        }
      }
      contactCtaText
      dataType
      dataLabels {
        file {
          url
        }
      }
      solutionType
    }
  }
`;
