import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Map from '../Map';

const DataMap = ({
  dataUrl,
  dataLabelsUrl,
  dataType,
  inView = true,
  interactive = true,
}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [labels, setLabels] = useState([]);

  useEffect(() => {
    Promise.all([fetch(dataUrl), fetch(dataLabelsUrl)])
      .then((responses) => {
        for (const response of responses) {
          if (!response.ok) {
            throw Error(response.statusText);
          }
        }
        return Promise.all(responses.map((response) => response.json()));
      })
      .then((responseData) => {
        setData(responseData[0]);
        setLabels(responseData[1]);
        setLoading(false);
      })
      .catch((error) => console.error(error));
  }, [dataUrl, dataLabelsUrl]);

  return (
    <Map
      data={data}
      dataType={dataType}
      labels={labels}
      loading={loading}
      interactive={interactive}
      inView={inView}
    />
  );
};

DataMap.propTypes = {
  dataUrl: PropTypes.string,
  dataLabelsUrl: PropTypes.string,
  dataType: PropTypes.string,
};

export default DataMap;
