import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import ScrollSection from 'components/ScrollSection';

import Grid from 'components/_layouts/Grid';
import GridItem from 'components/_layouts/GridItem';
import Container from 'components/_layouts/Container';
import Spacer from 'components/Spacer';
import Button from 'components/Button';
import { Heading5, TextBody } from 'components/TextStyles';
import IconArrowheadLeft from 'components/_svgs/IconArrowheadLeft';
import IconArrowheadRight from 'components/_svgs/IconArrowheadRight';
import { ContextState } from '../Providers';
import PageTransitionLink from 'components/PageTransitionLink';
import { buttonVariants } from 'components/Button/index.style';
import DataMap from 'components/DataMap';
import AnimateSplitText from 'components/_animation/AnimateSplitText';
import AnimateFadeIn from 'components/_animation/AnimateFadeIn';
import { useState } from 'react';
import styled from 'styled-components';
import { clamp } from 'styles/utils/conversion.style';
import { breakpoints } from 'styles/vars/breakpoints.style';
import { colors } from 'styles/vars/colors.style';
import { font } from 'styles/vars/font.style';
import { grid } from 'styles/vars/grid.style';
import { zIndex } from 'styles/vars/layout.style';
import { mq } from 'styles/vars/media-queries.style';

const Solution = (props) => {
  const {
    title,
    metaDescription,
    modalities,
    samples,
    participants,
    emotions,
    nationalities,
    emotionRatings,
    relevantPublications,
    data,
    dataType,
    dataLabels,
    contactCtaText,
    solutionType,
  } = props.data;
  const dataUrl = data?.file.url;
  const dataLabelsUrl = dataLabels?.file.url;
  const metaData = [
    { label: 'Modalities', value: modalities },
    { label: 'Samples', value: samples },
    { label: 'Participants', value: participants },
    { label: 'Emotions', value: emotions },
    { label: 'Nationalities', value: nationalities },
    { label: 'Emotion Ratings', value: emotionRatings },
    { label: 'Relevant publications', value: relevantPublications },
  ];
  const [load, setLoad] = useState(false);
  const store = useContext(ContextState);

  const {
    site: {
      siteMetadata: { signUpURL },
    },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          signUpURL
        }
      }
    }
  `);

  const validMetaData = metaData.reduce((arr, item) => {
    const value = item.value;

    if (value) {
      if (Array.isArray(value)) {
        const publicationsList = React.Children.toArray(
          value.map((publication, index) => (
            <>
              {index > 0 && <Spacer />}
              <a href={publication.url} target="_blank" rel="noreferrer">
                {publication.title}
              </a>
            </>
          )),
        );

        arr.push({ label: item.label, value: publicationsList });
      } else {
        arr.push(item);
      }
    }
    return arr;
  }, []);

  setTimeout(() => {
    setLoad(true);
  }, 1000);

  return (
    <ScrollSection>
      <SolutionMain>
        <Container>
          <Grid>
            <GridItem tabletL={11} desk={12} tabletLOrder={2}>
              <SolutionMap>
                {dataUrl && dataLabelsUrl && (
                  <DataMap
                    dataUrl={dataUrl}
                    dataType={dataType}
                    dataLabelsUrl={dataLabelsUrl}
                  />
                )}
              </SolutionMap>
            </GridItem>

            <GridItem tabletL={5} desk={4} tabletLOrder={1}>
              <SolutionSidebar data-inview={load}>
                <SolutionSidebarFooter>
                  <SolutionSidebarLink>
                    <AnimateFadeIn>
                      <PageTransitionLink
                        to={store.previousPathname}
                        scrollSection={solutionType.toLowerCase()}
                      >
                        <Button
                          variant={buttonVariants.plain}
                          iconLeft={<IconArrowheadLeft />}
                        >
                          Back {` `}
                        </Button>
                      </PageTransitionLink>
                    </AnimateFadeIn>
                  </SolutionSidebarLink>

                  <Spacer size={100} />

                  <Heading5>
                    <AnimateSplitText delay={0.2}>{title}</AnimateSplitText>
                  </Heading5>

                  <Spacer size={18} />

                  {metaDescription && (
                    <TextBody>
                      <AnimateSplitText delay={0.4}>
                        {metaDescription.metaDescription}
                      </AnimateSplitText>
                    </TextBody>
                  )}

                  {validMetaData.length > 0 && (
                    <>
                      <Spacer size={48} />

                      <SolutionTable>
                        <tbody>
                          {React.Children.toArray(
                            validMetaData.map((item, metaIndex) => (
                              <SolutionTableRow>
                                <SolutionTableLabel>
                                  <AnimateSplitText
                                    delay={0.6 + metaIndex * 0.1}
                                  >
                                    {item.label}
                                  </AnimateSplitText>
                                </SolutionTableLabel>
                                <SolutionTableValue>
                                  <AnimateSplitText
                                    delay={0.6 + metaIndex * 0.1}
                                  >
                                    {item.value}
                                  </AnimateSplitText>
                                </SolutionTableValue>
                              </SolutionTableRow>
                            )),
                          )}
                        </tbody>
                      </SolutionTable>
                    </>
                  )}

                  <Spacer size={48} />

                  <AnimateFadeIn delay={0.8}>
                    <Button
                      as={`a`}
                      href={signUpURL}
                      iconRight={<IconArrowheadRight />}
                    >
                      {contactCtaText}
                    </Button>
                  </AnimateFadeIn>
                </SolutionSidebarFooter>
              </SolutionSidebar>
            </GridItem>
          </Grid>
        </Container>
      </SolutionMain>
    </ScrollSection>
  );
};

Solution.propTypes = {
  data: PropTypes.object,
};

export default Solution;

const SolutionMain = styled.div`
  position: relative;
  z-index: ${zIndex.solution};
  background-color: ${colors.light};
  > div {
    min-height: 100vh;
  }
`;

const SolutionSidebar = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  ${clamp('padding-left', 22, 40)}
  ${clamp('padding-right', 22, 40)}
  ${clamp('padding-bottom', 48, 56)}
  ${mq.tabletLMax} {
    ${clamp('padding-top', 48, 60, breakpoints.mobile, breakpoints.tabletL)}
  }
  ${mq.tabletL} {
    height: 100vh;
    ${clamp('padding-top', 44, 44, breakpoints.tabletL)}
    ${clamp('margin-left', -grid.gap.min - 22, -grid.gap.max - 22)}
    ${clamp('margin-right', -grid.gap.min, -grid.gap.max)}
    overflow-x: hidden;
    overflow-y: scroll;
  }
`;

const SolutionSidebarFooter = styled.div`
  margin-top: auto;
`;

const SolutionSidebarLink = styled.div``;

const SolutionMap = styled.div`
  position: relative;
  margin-left: -2.2rem;
  margin-right: -2.2rem;
  height: 80vh;
  background-color: ${colors.black};
  ${mq.tabletL} {
    height: 100%;
    margin-left: 0;
  }
`;

const SolutionTable = styled.table`
  text-align: left;
`;

const SolutionTableRow = styled.tr`
  vertical-align: top;
`;

const SolutionTableLabel = styled.th`
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  padding-right: 1em;
  font-size: 1.6rem;
  line-height: 1.36;
  font-weight: ${font.weight.bold};
  text-transform: uppercase;
  letter-spacing: -0.01em;
`;

const SolutionTableValue = styled.td`
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  font-size: 1.6rem;
  line-height: 1.36;
  letter-spacing: -0.01em;
`;
