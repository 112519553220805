import React, { createRef } from 'react';
import gsap from 'gsap';
import * as THREE from 'three';
import ReactPlayer from 'react-player/file';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import ResourceTracker from './ResourceTracker';
import {
  CSS2DRenderer,
  CSS2DObject,
} from 'three/examples/jsm/renderers/CSS2DRenderer';
import Stats from 'three/examples/jsm/libs/stats.module';
import { random } from '../../utils/utils';
import Spacer from '../Spacer';
import { ScreenContext } from 'components/Providers';
import IconArrowheadRight from 'components/_svgs/IconArrowheadRight';
import Button from 'components/Button';
import { buttonThemes, buttonVariants } from 'components/Button/index.style';
import {
  TextBodySmall,
  TextBodyLarge,
  Heading2,
  Heading3,
} from '../TextStyles';

import {
  ExploreWrapper,
  AudioToggleButton,
  AudioToggleIcon,
  BackToMapBtn,
  DataImage,
  MapAudioBtn,
  MapCanvas,
  MapLabel,
  MapLoading,
  MapMain,
  MapPanel,
  MapPanelAudio,
  MapPanelHeading,
  MapPanelWaveform,
  MapPrompt,
  NavUI,
  NextParticleBtn,
  ParticleBorder,
  ParticleContent,
  ParticleContentMask,
  ParticleIndex,
  ParticleIndexMask,
  ParticleTitle,
  ParticleTitleMask,
  PreviousParticleBtn,
} from './index.style';
import DiscTexture from './images/disc.png';
import AudioWaveform from '../_svgs/AudioWaveform';
import { colors } from '../../styles/vars/colors.style';
import { isMobile } from 'react-device-detect';

const dat = typeof window !== 'undefined' ? require('dat.gui') : null;

class Map extends React.Component {
  constructor(props) {
    super(props);

    this.ref = {
      exploreButton: createRef(),
      wrapper: createRef(),
      canvas: createRef(),
      dataPanel: createRef(),
      dataPanelWaveform: createRef(),
      dataColor: createRef(),
      audioBtn: createRef(),
      particleIndex: createRef(),
      particleBorder: createRef(),
      particleTitle: createRef(),
      backToMap: createRef(),
      previousBtn: createRef(),
      nextBtn: createRef(),
      videoPlayerOne: createRef(),
      videoPlayerTwo: createRef(),
      labels: [],
    };

    this.state = {
      audioIsActive: false,
      activeParticle: null,
      activeParticleIndex: null,
      activeParticleTitle: null,
      dataColor: '#ffffff',
      dataEmotions: '',
      dataPanelIsOpen: false,
      audioBtnDisabled: false,
      audioIsPlaying: null,
      videoIsPlaying: false,
      imageLoaded: false,
      mediaFileURL: null,
      reactionFileURL: null,
    };

    this.resTracker = new ResourceTracker();
    this.track = this.resTracker.track.bind(this.resTracker);

    this.stats = null;
    this.raycaster = new THREE.Raycaster();
    this.mouse = new THREE.Vector2();
    this.lightColor = new THREE.Color(colors.light);
    this.currentIntersect = null;
    this.wasDragged = false;
    this.dragging = false;
    this.audioPlayer = false;
    this.particleScaleFactor = 0.1;
    this.panelYOffset = 40;
    this.cameraAnimationDuration = 1.4;
    this.positionScaleFactor = 1.4;
    this.defaultCameraPositionZ = 1.8;
    this.initCameraPositionZ = 3;
    this.loadedVideos = 0;
    this.spotLightZ = 0.5;
    this.spotLightIntensity = 0.8;
    this.interactive = props.interactive;
    this.audioPlaybackFiles = [];

    this.resetCamera = this.resetCamera.bind(this);
    this.onPreviousClick = this.onPreviousClick.bind(this);
    this.onNextClick = this.onNextClick.bind(this);
    this.handleAudioToggle = this.handleAudioToggle.bind(this);
    this.setRandomParticle = this.setRandomParticle.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.loading !== this.props.loading && !this.props.loading) {
      this.data = this.props.data;
      this.init();
    }

    if (this.state.activeParticle !== prevState.activeParticle) {
      this.updateParticleContent();
      this.updateParticleBorder();

      if (this.state.activeParticle) {
        this.openParticle();
        this.openBackToMapBtn();
        this.closeExploreButton();
      } else {
        this.closeBackToMapBtn();
        this.closeParticle();
        this.openExploreButton();
      }
    }
  }

  componentWillUnmount() {
    if (this.clouds) {
      this.clouds.length = 0;
    }
    this.particleObjects.length = 0;
    this.audioPlaybackFiles.length = 0;
    this.resTracker.dispose();
    this.renderer.dispose();
    this.controls.dispose();
    window.cancelAnimationFrame(this.raf);

    this.stopHoverAudio();
    this.ref.wrapper.current.removeEventListener('mousemove', this.onMousemove);

    if (this.interactive) {
      this.ref.wrapper.current.removeEventListener(
        'pointerdown',
        this.onPointerdown,
      );
      this.ref.wrapper.current.removeEventListener(
        'pointerup',
        this.onPointerup,
      );
      this.ref.wrapper.current.removeEventListener('click', this.onClick);
      this.ref.wrapper.current.removeEventListener(
        'touchstart',
        this.onTouchstart,
      );
      window.removeEventListener('keydown', this.onKeydown);
    }
    window.removeEventListener('resize', this.resizeHandler);

    if (this.audioPlayer) {
      this.ref.audioBtn.current.removeEventListener(
        'click',
        this.audioBtnClickHandler,
      );

      this.audioPlayer.removeEventListener(
        'loadstart',
        this.audioLoadstartHandler,
      );
      this.audioPlayer.removeEventListener('play', this.audioPlayHandler);
      this.audioPlayer.removeEventListener(
        'canplaythrough',
        this.audioCanPlayThroughHandler,
      );
      this.audioPlayer.removeEventListener('ended', this.audioEndedHandler);
      this.audioPlayer.removeEventListener('pause', this.audioPauseHandler);
    }
  }

  setGUI() {
    const gui = new dat.GUI({ width: 360 });
    // gui.close()

    const ambientLight = gui.addFolder('Ambient light');
    ambientLight.add(this.ambientLight, 'intensity').min(0).max(1).step(0.1);
    // ambientLight.open()

    const directionalLights = gui.addFolder('Directional Light');
    directionalLights
      .add(this.directionalLight, 'intensity')
      .min(0)
      .max(2)
      .step(0.1);
    directionalLights
      .add(this.directionalLight.target.position, 'x')
      .min(-10)
      .max(10);
    directionalLights
      .add(this.directionalLight.target.position, 'y')
      .min(0)
      .max(10);
    directionalLights
      .add(this.directionalLight.target.position, 'z')
      .min(-10)
      .max(10);
    // directionalLights.open()

    const controls = gui.addFolder('Controls');
    controls.add(this.controls.target, 'x').min(-1).max(1).step(0.01);
    controls.add(this.controls.target, 'y').min(-1).max(1).step(0.01);
    // controls.open()

    const camera = gui.addFolder('Camera');
    camera.add(this.camera.position, 'x').min(-1).max(1).step(0.01);
    camera.add(this.camera.position, 'y').min(-1).max(1).step(0.01);
    camera.add(this.camera.position, 'z').min(-2).max(2).step(0.01);
    camera.add(this.camera, 'near').min(0).max(1).step(0.01);
    camera.add(this.camera, 'far').min(50).max(200).step(1);
    // camera.open()

    const updateLight = () => {
      this.spotLightHelper.update();
    };

    class DegRadHelper {
      constructor(obj, prop) {
        this.obj = obj;
        this.prop = prop;
      }
      get value() {
        return THREE.MathUtils.radToDeg(this.obj[this.prop]);
      }
      set value(v) {
        this.obj[this.prop] = THREE.MathUtils.degToRad(v);
      }
    }

    if (!isMobile) {
      const pointLight = gui.addFolder('Point light');
      pointLight
        .add(new DegRadHelper(this.spotLight, 'angle'), 'value', 0, 90)
        .name('angle')
        .onChange(updateLight);
      pointLight
        .add(this.spotLight, 'intensity')
        .min(0)
        .max(10)
        .step(0.1)
        .onChange(updateLight);
      pointLight.open();
    }
  }

  setStats() {
    if (typeof document !== 'undefined') {
      this.stats = new Stats();
      document.body.appendChild(this.stats.dom);
    }
  }

  loadAudio(src) {
    if (!this.audioPlayer && typeof document !== 'undefined') {
      this.audioPlayer = document.createElement('audio');

      this.audioBtnClickHandler = (e) => {
        if (this.state.audioIsPlaying) {
          this.audioPlayer.pause();
        } else {
          this.audioPlayer.currentTime = 0;
          this.audioPlayer.play();
        }
      };

      this.audioLoadstartHandler = () => {
        this.setState({ audioIsPlaying: null, audioBtnDisabled: true });
      };

      this.audioPlayHandler = () => {
        this.setState({
          audioBtnDisabled: false,
          audioIsPlaying: true,
        });
      };

      this.audioCanPlayThroughHandler = () => {
        if (!this.state.audioIsPlaying) {
          this.setState({ audioIsPlaying: false, audioBtnDisabled: false });
        }
        this.audioPlayer.play();
      };

      this.audioEndedHandler = () => {
        this.setState({ audioIsPlaying: false });
      };

      this.audioPauseHandler = () => {
        this.setState({ audioIsPlaying: false });
      };

      this.ref.audioBtn.current.addEventListener(
        'click',
        this.audioBtnClickHandler,
      );
      this.audioPlayer.addEventListener(
        'loadstart',
        this.audioLoadstartHandler,
      );
      this.audioPlayer.addEventListener('play', this.audioPlayHandler);
      this.audioPlayer.addEventListener(
        'canplaythrough',
        this.audioCanPlayThroughHandler,
      );
      this.audioPlayer.addEventListener('ended', this.audioEndedHandler);
      this.audioPlayer.addEventListener('pause', this.audioPauseHandler);
    } else {
      this.audioPlayer.pause();
    }

    if (this.audioPlayer.canPlayType('audio/mpeg')) {
      this.audioPlayer.setAttribute('src', src);
      this.audioPlayer.load();
    }
  }

  setScene() {
    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color('#232323');
    this.scene.fog = new THREE.FogExp2('#232323', 0.05);
  }

  setLights() {
    this.ambientLight = new THREE.AmbientLight();
    this.ambientLight.color = new THREE.Color(0xffffff);
    this.ambientLight.intensity = 1;
    this.scene.add(this.ambientLight);

    this.directionalLight = new THREE.DirectionalLight(0xffffff, 0.5);
    this.directionalLight.position.set(1, 0.25, 0);
    this.directionalLight.target.position.set(1, 5, -10);
    this.directionalLightHelper = new THREE.DirectionalLightHelper(
      this.directionalLight,
      5,
    );
    this.scene.add(this.directionalLight);
    this.scene.add(this.directionalLight.target);

    if (!isMobile && this.interactive) {
      this.spotLight = new THREE.SpotLight(this.lightColor);
      this.spotLight.position.set(0, 0, this.spotLightZ);
      this.spotLight.intensity = this.spotLightIntensity;
      this.spotLight.penumbra = 1;
      this.spotLight.angle = THREE.MathUtils.degToRad(35);
      this.scene.add(this.spotLight);
      this.scene.add(this.spotLight.target);
    }

    // this.spotLightHelper = new THREE.SpotLightHelper(this.spotLight)
    // this.scene.add(this.spotLightHelper)
    // this.scene.add(this.directionalLightHelper)
  }

  setCloud() {
    const texture = this.track(new THREE.TextureLoader().load(DiscTexture));
    const geometry = this.track(new THREE.BufferGeometry());
    const particlesPerDirection = 300;
    const particleDirections = 20;

    const radius = 5;
    const positions = [];

    for (let i = 0; i < particlesPerDirection; i++) {
      positions.push((Math.random() * 2 - 1) * radius); // x
      positions.push((Math.random() * 2 - 1) * radius); // y
      positions.push((Math.random() * 2 - 1) * radius); // z
    }

    geometry.setAttribute(
      'position',
      new THREE.Float32BufferAttribute(positions, 3),
    );

    this.clouds = [];

    for (let i = 0; i < particleDirections; i++) {
      const material = this.track(
        new THREE.PointsMaterial({
          size: Math.random() * 0.015,
          color: 0xb5b5b5,
          map: texture,
          blending: THREE.AdditiveBlending,
          transparent: true,
        }),
      );

      this.cloud = this.track(new THREE.Points(geometry, material));

      this.cloud.rotation.x = Math.random() * 6;
      this.cloud.rotation.y = Math.random() * 6;
      this.cloud.rotation.z = Math.random() * 6;

      this.scene.add(this.cloud);
      this.clouds.push(this.cloud);
    }
  }

  setGeometry() {
    const xValues = this.data.map((d) => d.X);
    const yValues = this.data.map((d) => d.Y);
    const geometry = this.track(new THREE.SphereBufferGeometry(0.005, 32, 32));

    this.xMax = Math.max(...xValues);
    this.yMax = Math.max(...yValues);
    this.particleObjects = [];
    this.particlePositions = [];

    for (let i = 0; i < this.data.length; i++) {
      const item = this.data[i];
      const color = new THREE.Color(item.Color);
      const material = this.track(new THREE.MeshLambertMaterial({ color }));

      material.transparent = true;

      const mesh = this.track(new THREE.Mesh(geometry, material));

      const pos = {
        x: (item.X / this.xMax - 0.5) * this.positionScaleFactor,
        y: (item.Y / this.yMax - 0.5) * this.positionScaleFactor,
        z: (random() - 0.5) * 0.2,
      };

      const centerPosVector = new THREE.Vector3();
      const posVector = new THREE.Vector3(pos.x, pos.y, pos.z);

      pos.distance = posVector.distanceTo(centerPosVector);

      this.particlePositions.push(pos);

      mesh.userData = { index: i, data: item };
      // mesh.position.x = (Math.random() * 5 - 2.5)
      // mesh.position.y = (Math.random() * 5 - 2.5)
      mesh.position.x = pos.x;
      mesh.position.y = pos.y;
      mesh.position.z = Math.random() * 0.25;

      this.scene.add(mesh);
      this.particleObjects.push({
        mesh,
        scale: { current: 1, target: 1 },
        opacity: { current: 0, target: 0.0001 },
      });
    }
  }

  setLabels() {
    if (!this.props.labels.length) return;

    for (let i = 0; i < this.props.labels.length; i++) {
      const label = this.props.labels[i];
      const object = this.track(new CSS2DObject(this.ref.labels[i]));

      // this.ref.labels[i].style.opacity = 0 // Temp

      object.position.set(
        (label.X / this.xMax - 0.5) * this.positionScaleFactor,
        (label.Y / this.yMax - 0.5) * this.positionScaleFactor,
        -0.07,
      );
      this.scene.add(object);
    }
  }

  setSizes() {
    this.sizes = {
      width: this.ref.wrapper.current.offsetWidth,
      height: this.ref.wrapper.current.offsetHeight,
      sidebarWidth: window.innerWidth - this.ref.wrapper.current.offsetWidth,
    };

    this.resizeHandler = () => {
      // Update sizes
      this.sizes.width = this.ref.wrapper.current.offsetWidth;
      this.sizes.height = this.ref.wrapper.current.offsetHeight;
      this.sizes.sidebarWidth =
        window.innerWidth - this.ref.wrapper.current.offsetWidth;

      // Update camera
      this.camera.aspect = this.sizes.width / this.sizes.height;
      this.camera.updateProjectionMatrix();

      // Update renderer
      this.renderer.setSize(this.sizes.width, this.sizes.height);
      this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

      // Update label renderer
      if (this.interactive) {
        this.labelRenderer.setSize(this.sizes.width, this.sizes.height);
      }
    };

    window.addEventListener('resize', this.resizeHandler);
  }

  setCamera() {
    this.camera = new THREE.PerspectiveCamera(
      50,
      this.sizes.width / this.sizes.height,
      0.05,
      10,
    );
    this.camera.position.x = 0;
    this.camera.position.y = 0;
    this.camera.position.z = this.initCameraPositionZ;
    this.scene.add(this.camera);
  }

  setControls() {
    this.controls = new OrbitControls(this.camera, this.ref.canvas.current);
    this.controls.enableDamping = true;
    this.controls.dampingFactor = 0.05;

    if (!this.interactive) {
      this.controls.enabled = false;
    }

    // Vertical limits
    this.controls.minPolarAngle = Math.PI * 0.05;
    this.controls.maxPolarAngle = Math.PI * 0.95;
    // // Horizontal limits
    this.controls.minAzimuthAngle = -Math.PI * 0.4;
    this.controls.maxAzimuthAngle = Math.PI * 0.4;
    // // Zoom limits
    this.controls.maxDistance = this.initCameraPositionZ;
  }

  setRenderer() {
    this.renderer = new THREE.WebGLRenderer({
      canvas: this.ref.canvas.current,
      powerPreference: 'high-performance',
    });
    this.renderer.setSize(this.sizes.width, this.sizes.height);
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

    if (this.interactive) {
      this.labelRenderer = new CSS2DRenderer();
      this.labelRenderer.setSize(this.sizes.width, this.sizes.height);
      this.ref.wrapper.current.appendChild(this.labelRenderer.domElement);
    }
  }

  startingAnimations() {
    for (let x = 0; x < this.particleObjects.length; x++) {
      // const delay = 2 * this.particlePositions[x].distance
      const delay = 1 * (x / this.particleObjects.length);

      gsap.to(this.particleObjects[x].opacity, {
        target: 1,
        duration: 2,
        delay: delay,
        ease: 'power1.out',
      });

      gsap.to(this.particleObjects[x].mesh.position, {
        x: this.particlePositions[x].x,
        y: this.particlePositions[x].y,
        z: this.particlePositions[x].z,
        duration: 2,
        delay: delay,
        ease: 'power2.inOut',
      });
    }

    gsap.to(this.camera.position, {
      z: this.defaultCameraPositionZ,
      duration: 3.5,
      ease: 'power3.inOut',
      onComplete: () => {
        this.controls.maxDistance = this.defaultCameraPositionZ;
      },
    });
  }

  openBackToMapBtn() {
    const btn = this.ref.backToMap.current;

    gsap.to(btn, {
      opacity: 1,
      duration: 0.2,
      delay: this.cameraAnimationDuration - 0.2,
      ease: 'power3.inOut',
    });
  }

  closeBackToMapBtn() {
    const btn = this.ref.backToMap.current;

    gsap.to(btn, {
      opacity: 0,
      duration: 0.2,
      overwrite: true,
      ease: 'power3.inOut',
    });
  }

  updateParticleBorder() {
    const { activeParticle, activeParticleIndex } = this.state;
    const border = this.ref.particleBorder.current;
    const tl = gsap.timeline();

    const scaleIn = () =>
      gsap.to(border, {
        scale: 1,
        duration: 0.2,
        delay: this.cameraAnimationDuration - 0.2,
        ease: 'power3.out',
      });

    const scaleOut = () =>
      gsap.to(border, {
        scale: 0,
        duration: 0.2,
        ease: 'power3.out',
      });

    if (activeParticle) {
      if (activeParticleIndex === null) {
        tl.add(scaleIn());
      } else {
        tl.add(scaleOut());
        tl.add(scaleIn());
      }
    }

    if (!activeParticle) {
      tl.add(scaleOut());
    }
  }

  updateParticleContent() {
    const { activeParticle, activeParticleIndex } = this.state;
    const indexRef = this.ref.particleIndex.current;
    const titleRef = this.ref.particleTitle.current;
    const tl = gsap.timeline();

    const setIndexContent = () => {
      const newParticleIndex = activeParticle.userData.index
        .toString()
        .padStart(5, '0');

      this.setState({
        activeParticleIndex: newParticleIndex,
      });
    };

    const setTitleContent = () => {
      const {
        Color,
        File,
        Reaction,
        X,
        Y,
        ...emotions
      } = activeParticle.userData.data;
      const emotionsArray = Object.keys(emotions)
        .sort((a, b) => emotions[a] - emotions[b])
        .reverse()
        .reduce((arr, emotion) => {
          arr.push(emotion);
          return arr;
        }, []);

      this.setState({
        activeParticleTitle: emotionsArray[0],
      });
    };

    const slideIn = (el, delay = 0) =>
      gsap.to(el, {
        yPercent: -100,
        duration: 0.2,
        delay,
        ease: 'power3.out',
      });

    const slideOut = (el, onComplete) =>
      gsap.to(el, {
        yPercent: 100,
        duration: 0.2,
        ease: 'power3.in',
        onComplete,
      });

    if (activeParticle) {
      if (activeParticleIndex === null) {
        setIndexContent();
        setTitleContent();

        tl.add(slideIn(indexRef, 0.4));
        tl.add(slideIn(titleRef));
      } else {
        const tl = gsap.timeline();

        tl.add(slideOut(indexRef, setIndexContent));
        tl.add(slideOut(titleRef, setTitleContent), 0);
        tl.add(slideIn(indexRef, 0.5));
        tl.add(slideIn(titleRef));
      }
    }

    if (!activeParticle) {
      tl.add(
        slideOut([indexRef, titleRef], () =>
          this.setState({
            activeParticleIndex: null,
            activeParticleTitle: null,
          }),
        ),
      );
    }
  }

  updateDataPanel() {
    const {
      Color,
      File,
      Reaction,
      X,
      Y,
      ...emotions
    } = this.state.activeParticle.userData.data;
    const { dataType } = this.props;
    const emotionsString = Object.keys(emotions)
      .sort((a, b) => emotions[a] - emotions[b])
      .reverse()
      .reduce((arr, emotion) => {
        const emotionValue = emotions[emotion];
        arr.push(`${Math.floor(emotionValue * 100)}% ${emotion}`);
        return arr;
      }, [])
      .join(' + ');

    this.loadedVideos = 0;
    this.setState(
      {
        mediaFileURL: null,
        reactionFileURL: null,
      },
      () => {
        if (dataType === 'Image') {
          this.setState({
            imageLoaded: false,
            mediaFileURL: File,
          });
        }

        if (dataType === 'Video') {
          this.setState({
            videoIsPlaying: false,
            mediaFileURL: File,
            reactionFileURL: Reaction,
          });
        }

        if (!this.state.dataPanelIsOpen) {
          if (dataType === 'Audio') {
            this.loadAudio(File);
          }

          this.setState({
            dataEmotions: emotionsString,
            dataColor: Color,
          });

          this.setState({ dataPanelIsOpen: true });

          gsap.fromTo(
            this.ref.dataPanel.current,
            {
              opacity: 0,
              y: this.panelYOffset,
            },
            {
              opacity: 1,
              y: 0,
              ease: 'power2.inOut',
              duration: 0.25,
            },
          );
        } else {
          const tl = gsap.timeline();

          tl.to(this.ref.dataPanel.current, {
            opacity: 0,
            y: this.panelYOffset,
            ease: 'power3.inOut',
            duration: 0.15,
            onComplete: () => {
              if (dataType === 'Audio') {
                this.loadAudio(File);
              }

              this.setState({
                dataEmotions: emotionsString,
                dataColor: Color,
              });
            },
          }).to(this.ref.dataPanel.current, {
            delay: 0.1,
            opacity: 1,
            y: 0,
            ease: 'power3.inOut',
            duration: 0.25,
            onComplete: () => {
              this.setState({ dataPanelIsOpen: true });
            },
          });
        }
      },
    );
  }

  closeDataPanel() {
    gsap.to(this.ref.dataPanel.current, {
      opacity: 0,
      y: this.panelYOffset,
      ease: 'power3.inOut',
      duration: 0.15,
      onComplete: () => {
        this.setState({ dataPanelIsOpen: false });

        if (this.state.audioIsPlaying) {
          this.audioPlayer.pause();
        }
      },
    });
  }

  showImage() {
    this.setState({ imageLoaded: true });
  }

  resetCamera() {
    const tl = gsap.timeline();

    tl.to(this.camera.position, {
      x: 0,
      y: 0,
      z: this.defaultCameraPositionZ,
      duration: this.cameraAnimationDuration,
      ease: 'power3.inOut',
    }).to(
      this.controls.target,
      {
        x: 0,
        y: 0,
        z: 0,
        duration: this.cameraAnimationDuration,
        ease: 'power3.inOut',
      },
      0,
    );
  }

  onPreviousClick() {
    if (!this.state.activeParticle) return;

    const activeParticleIndex = this.particleObjects
      .map((particle) => particle.mesh)
      .indexOf(this.state.activeParticle);

    const newIndex =
      activeParticleIndex === 0
        ? this.particleObjects.length - 1
        : activeParticleIndex - 1;

    this.setState({
      activeParticle: this.particleObjects[newIndex].mesh,
    });
  }

  onNextClick() {
    if (!this.state.activeParticle) return;

    const activeParticleIndex = this.particleObjects
      .map((particle) => particle.mesh)
      .indexOf(this.state.activeParticle);

    const newIndex =
      activeParticleIndex === this.particleObjects.length - 1
        ? 0
        : activeParticleIndex + 1;

    this.setState({
      activeParticle: this.particleObjects[newIndex].mesh,
    });
  }

  centerOnParticle() {
    const object = this.state.activeParticle;
    const boundingBox = new THREE.Box3();
    boundingBox.setFromObject(object);
    const meshCenter = boundingBox.getCenter(object.position);

    for (const particle of this.particleObjects) {
      if (particle.mesh !== object) {
        particle.opacity.target = 0.4;
        particle.scale.target = 0.4;
      } else {
        particle.opacity.target = 1;
        particle.scale.target = 1.3;
      }
    }

    const tl = gsap.timeline();

    tl.to(this.camera.position, {
      x: meshCenter.x,
      y: meshCenter.y,
      z: meshCenter.z + 0.17,
      duration: this.cameraAnimationDuration,
      ease: 'power3.inOut',
    });
    tl.to(
      this.controls.target,
      {
        x: meshCenter.x,
        y: meshCenter.y,
        z: meshCenter.z,
        duration: this.cameraAnimationDuration,
        ease: 'power3.inOut',
      },
      0,
    );
  }

  openParticle() {
    this.updateDataPanel();
    this.centerOnParticle();
  }

  closeParticle() {
    this.closeDataPanel();
  }

  playAudioOnHover(mesh) {
    const audio = {
      file: mesh.userData.data.File,
      player: document.createElement('audio'),
    };

    if (
      this.audioPlaybackFiles.length > 10 ||
      this.audioPlaybackFiles.map((obj) => obj.file).indexOf(audio.file) > -1
    )
      return;

    this.audioPlaybackFiles.push(audio);
    audio.player.src = audio.file;
    audio.player.volume = 0.5;
    audio.player.play();

    audio.player.addEventListener('ended', () => {
      this.audioPlaybackFiles = this.audioPlaybackFiles.splice(
        this.audioPlaybackFiles.map((obj) => obj.file).indexOf(audio.file),
        1,
      );
    });
  }

  _setRaycaster() {
    this.raycaster.setFromCamera(this.mouse, this.camera);

    const intersects = this.raycaster.intersectObjects(
      this.particleObjects.map((item) => item.mesh),
    );

    if (intersects.length) {
      this.currentIntersect = intersects[0];

      if (!this.state.activeParticle) {
        if (this.state.audioIsActive) {
          this.playAudioOnHover(this.currentIntersect.object);
        }

        for (const particle of this.particleObjects) {
          if (particle.mesh === this.currentIntersect.object) {
            particle.scale.target = 1.5 + this.camera.position.z;
          } else {
            particle.scale.target = 1;
          }
        }
      }
    } else {
      this.currentIntersect = null;

      if (!this.state.activeParticle) {
        for (const particle of this.particleObjects) {
          particle.scale.target = 1;
        }
      }
    }
  }

  _updateCloud() {
    const time = Date.now() * 0.0000015;

    for (let i = 0; i < this.clouds.length; i++) {
      this.clouds[i].rotation.y = time * i;
    }
  }

  _updateParticles() {
    for (const particle of this.particleObjects) {
      if (particle.scale.current !== particle.scale.target) {
        particle.scale.current +=
          (particle.scale.target - particle.scale.current) *
          this.particleScaleFactor;

        particle.mesh.scale.set(
          particle.scale.current,
          particle.scale.current,
          particle.scale.current,
        );
      }

      if (particle.opacity.current !== particle.opacity.target) {
        particle.opacity.current +=
          (particle.opacity.target - particle.opacity.current) *
          this.particleScaleFactor;

        particle.mesh.material.opacity = particle.opacity.current;
      }
    }
  }

  _positionPointLight() {
    if (!this.state.activeParticle && !isMobile && this.interactive) {
      this.spotLight.position.set(this.mouse.x, this.mouse.y, this.spotLightZ);
      this.spotLight.target.position.set(this.mouse.x, this.mouse.y, 0);
    }
  }

  _tiltScene() {
    const { x, y, z } = this.camera.position;
    const controlsInitZ = this.defaultCameraPositionZ - 0.5;

    if (!this.dragging && !this.state.activeParticle) {
      const mouseX = this.mouse.x;
      const mouseY = this.mouse.y;
      const lerpFactor = 0.09;
      const scaleFactor = 0.2;
      const xTargetOut =
        z < controlsInitZ
          ? x + -mouseX * scaleFactor * z
          : mouseX * scaleFactor * z;
      const yTargetOut =
        z < controlsInitZ
          ? y + -mouseY * scaleFactor * z
          : mouseY * scaleFactor * z;
      const newX = x + lerpFactor * (xTargetOut - x);
      const newY = y + lerpFactor * (yTargetOut - y);

      this.camera.position.x = newX;
      this.camera.position.y = newY;
    }
  }

  _renderLoop() {
    if (this.props.inView) {
      this._renderFrame();
    }

    this.raf = window.requestAnimationFrame(this._renderLoop.bind(this));
  }

  clickedOnDataPanel(target) {
    if (
      target.parentElement !== this.ref.dataPanel.current &&
      target.parentElement !== this.ref.dataPanelWaveform.current &&
      target !== this.ref.dataPanel.current &&
      target !== this.ref.audioBtn.current &&
      target !== this.ref.previousBtn.current &&
      target !== this.ref.nextBtn.current
    ) {
      return false;
    } else {
      return true;
    }
  }

  _handleMouseEvents() {
    this.onMousemove = (e) => {
      this.mouse.x =
        ((e.clientX - this.sizes.sidebarWidth) / this.sizes.width) * 2 - 1;
      this.mouse.y = -(e.clientY / this.sizes.height) * 2 + 1;
    };

    this.onPointerdown = (e) => {
      this.wasDragged = false;
      this.dragging = true;
      this.dragStartX = e.clientX;
      this.dragStartY = e.clientY;
    };

    this.onPointerup = (e) => {
      const xDiff = Math.abs(this.dragStartX - e.clientX);
      const yDiff = Math.abs(this.dragStartY - e.clientY);

      this.dragging = false;

      if (xDiff > 5 || yDiff > 5) {
        this.wasDragged = true;
      }

      if (!this.clickedOnDataPanel(e.target)) {
        for (const particle of this.particleObjects) {
          particle.opacity.target = 1;
          particle.scale.target = 1;
        }
      }
    };

    this.onClick = (e) => {
      if (this.currentIntersect && !this.wasDragged) {
        const currentMesh = this.currentIntersect.object;
        const spotLightPositionX = currentMesh.position.x;
        const spotLightPositionY = currentMesh.position.y;
        const spotLightPositionZ = currentMesh.position.z + this.spotLightZ;

        if (!isMobile && this.interactive) {
          this.spotLight.color = currentMesh.material.color;
          this.spotLight.intensity = 3;
          this.spotLight.position.set(
            spotLightPositionX,
            spotLightPositionY,
            spotLightPositionZ,
          );
        }

        // this.controls.enableZoom = false
        this.setState({ activeParticle: currentMesh });
      } else {
        if (!this.clickedOnDataPanel(e.target)) {
          this.setState({ activeParticle: null });
          // this.controls.enableZoom = true

          if (!isMobile && this.interactive) {
            this.spotLight.color = this.lightColor;
            this.spotLight.intensity = this.spotLightIntensity;
          }
        }
      }
    };

    this.onTouchstart = (e) => {
      const touchRaycaster = new THREE.Raycaster();
      const touchCoords = new THREE.Vector2();
      touchCoords.x =
        ((e.targetTouches[0].clientX - this.sizes.sidebarWidth) /
          this.sizes.width) *
          2 -
        1;
      touchCoords.y = -(e.targetTouches[0].clientY / this.sizes.height) * 2 + 1;
      touchRaycaster.setFromCamera(touchCoords, this.camera);

      const intersects = touchRaycaster.intersectObjects(
        this.particleObjects.map((item) => item.mesh),
      );

      if (intersects.length) {
        const currentIntersect = intersects[0];

        if (!this.state.activeParticle) {
          for (const particle of this.particleObjects) {
            if (particle.mesh === currentIntersect.object) {
              particle.scale.target = 1.5 + this.camera.position.z;
            } else {
              particle.scale.target = 1;
            }
          }
        }

        this.setState({ activeParticle: currentIntersect.object });
      } else {
        if (!this.state.activeParticle) {
          for (const particle of this.particleObjects) {
            particle.scale.target = 1;
          }
        }
        if (!this.clickedOnDataPanel(e.target)) {
          this.setState({ activeParticle: null });
        }
      }
    };

    this.onKeydown = (e) => {
      if (!this.state.activeParticle) {
        return false;
      }

      if (e.which === 37 || e.which === 39) {
        const activeParticleIndex = this.particleObjects
          .map((particle) => particle.mesh)
          .indexOf(this.state.activeParticle);

        let newIndex;

        if (e.which === 37) {
          newIndex =
            activeParticleIndex === 0
              ? activeParticleIndex.length - 1
              : activeParticleIndex - 1;
        }

        if (e.which === 39) {
          newIndex =
            activeParticleIndex === activeParticleIndex.length - 1
              ? 0
              : activeParticleIndex + 1;
        }

        this.setState({
          activeParticle: this.particleObjects[newIndex].mesh,
        });
      }
    };

    this.ref.wrapper.current.addEventListener('mousemove', this.onMousemove);

    if (this.interactive) {
      this.ref.wrapper.current.addEventListener(
        'pointerdown',
        this.onPointerdown,
      );
      this.ref.wrapper.current.addEventListener('pointerup', this.onPointerup);
      this.ref.wrapper.current.addEventListener('click', this.onClick);
      this.ref.wrapper.current.addEventListener(
        'touchstart',
        this.onTouchstart,
      );
      window.addEventListener('keydown', this.onKeydown);
    }
  }

  handleVideosLoading() {
    if (this.loadedVideos === 0) {
      this.loadedVideos = 1;
    } else if (this.loadedVideos === 1) {
      this.loadedVideos = 0;
      this.setState({ videoIsPlaying: true });
    }
  }

  stopHoverAudio() {
    this.audioPlaybackFiles.forEach((audio) => {
      audio.player.pause();
    });
    this.audioPlaybackFiles = [];
  }

  handleAudioToggle() {
    const { audioIsActive } = this.state;

    if (audioIsActive) {
      this.stopHoverAudio();
    }

    this.setState({ audioIsActive: !audioIsActive });
  }

  _renderFrame() {
    if (this.stats) {
      this.stats.update();
    }
    if (this.interactive) {
      if (!isMobile) {
        this._updateCloud();
      }
      this._updateParticles();
    }
    this._positionPointLight();
    if (!isMobile) {
      if (this.interactive) {
        this._setRaycaster();
      }
      this._tiltScene();
    }
    this.controls.update();
    this.renderer.render(this.scene, this.camera);
    if (this.interactive) {
      this.labelRenderer.render(this.scene, this.camera);
    }
  }

  setRandomParticle() {
    let index = Math.floor(Math.random() * this.particleObjects.length);

    this.setState({
      activeParticle: this.particleObjects[index].mesh,
    });
  }

  openExploreButton() {
    const screen = this.context;
    if (
      screen.tiny ||
      screen.mobile ||
      screen.mobileL ||
      screen.tabletP ||
      screen.tabletL
    ) {
      gsap.set(this.ref.exploreButton.current, {
        delay: 0.5,
        display: 'block',
        onComplete: () => {
          gsap.to(this.ref.exploreButton.current, {
            opacity: 1,
            duration: 0.5,
          });
        },
      });
    }
  }

  closeExploreButton() {
    gsap.to(this.ref.exploreButton.current, {
      opacity: 0,
      duration: 0.25,
      overwrite: true,
      onComplete: () => {
        gsap.set(this.ref.exploreButton.current, {
          display: 'none',
        });
      },
    });
  }

  init() {
    // this.setStats()
    this.setScene();
    this.setLights();
    this.setSizes();
    this.setCamera();
    this.setControls();
    if (this.interactive && !isMobile) {
      this.setCloud();
    }
    this.setGeometry();
    if (this.interactive) {
      this.setLabels();
    }
    this.setRenderer();
    this.startingAnimations();
    this._handleMouseEvents();
    // if (dat) {
    // this.setGUI()
    // }
    this._renderFrame();
    this.raf = window.requestAnimationFrame(this._renderLoop.bind(this));
  }

  render() {
    const { labels, loading, dataType, interactive } = this.props;
    const {
      dataColor,
      dataEmotions,
      dataPanelIsOpen,
      imageLoaded,
      audioBtnDisabled,
      audioIsPlaying,
      activeParticleIndex,
      activeParticleTitle,
      audioIsActive,
    } = this.state;
    const refs = this.ref;
    const audioBtnText =
      audioIsPlaying === null ? 'Loading...' : audioIsPlaying ? 'Stop' : 'Play';

    return (
      <>
        <MapMain ref={refs.wrapper}>
          <MapCanvas ref={refs.canvas} />

          {interactive && (
            <>
              <ExploreWrapper ref={refs.exploreButton}>
                <Button
                  theme={buttonThemes.light}
                  variant={buttonVariants.fill}
                  iconRight={<IconArrowheadRight />}
                  onClick={this.setRandomParticle}
                >
                  explore data
                </Button>
              </ExploreWrapper>

              <MapPanel ref={refs.dataPanel} open={dataPanelIsOpen}>
                {dataType === 'Audio' && (
                  <MapPanelAudio>
                    <MapPanelWaveform ref={refs.dataPanelWaveform}>
                      <AudioWaveform responsive fill={dataColor} />
                    </MapPanelWaveform>
                    <MapAudioBtn
                      ref={refs.audioBtn}
                      disabled={audioBtnDisabled}
                      playing={audioIsPlaying}
                    >
                      {audioBtnText}
                    </MapAudioBtn>
                  </MapPanelAudio>
                )}

                {dataType === 'Image' && this.state.mediaFileURL && (
                  <DataImage
                    src={this.state.mediaFileURL}
                    loaded={imageLoaded}
                    onLoad={this.showImage.bind(this)}
                  />
                )}

                {dataType === 'Video' && (
                  <>
                    {this.state.mediaFileURL && (
                      <ReactPlayer
                        playing={this.state.videoIsPlaying}
                        style={{ pointerEvents: 'none' }}
                        loop={true}
                        muted={true}
                        playsinline={true}
                        width={'100%'}
                        height={'auto'}
                        url={this.state.mediaFileURL}
                        onReady={this.handleVideosLoading.bind(this)}
                        config={{
                          file: {
                            forceVideo: true,
                          },
                        }}
                      />
                    )}
                    {this.state.reactionFileURL && (
                      <ReactPlayer
                        playing={this.state.videoIsPlaying}
                        style={{ pointerEvents: 'none' }}
                        loop={true}
                        muted={true}
                        playsinline={true}
                        width={'100%'}
                        height={'auto'}
                        url={this.state.reactionFileURL}
                        onReady={this.handleVideosLoading.bind(this)}
                        config={{
                          file: {
                            forceVideo: true,
                          },
                        }}
                      />
                    )}
                  </>
                )}

                <Spacer size={23} />

                <MapPanelHeading>Data breakdown</MapPanelHeading>

                <Spacer size={6} />

                {dataEmotions.length > 0 && (
                  <TextBodySmall>{dataEmotions}</TextBodySmall>
                )}
              </MapPanel>

              <ParticleContent>
                <ParticleIndexMask>
                  <ParticleContentMask>
                    <ParticleIndex ref={refs.particleIndex}>
                      <TextBodyLarge as="span">
                        Reaction #{activeParticleIndex && activeParticleIndex}
                      </TextBodyLarge>
                    </ParticleIndex>
                  </ParticleContentMask>
                </ParticleIndexMask>

                <ParticleTitleMask>
                  <ParticleContentMask>
                    <ParticleTitle ref={refs.particleTitle}>
                      <Heading2 as="span">
                        {activeParticleTitle && activeParticleTitle}
                      </Heading2>
                    </ParticleTitle>
                  </ParticleContentMask>
                </ParticleTitleMask>
              </ParticleContent>

              <ParticleBorder ref={refs.particleBorder} />

              <NavUI ref={refs.backToMap}>
                <PreviousParticleBtn
                  ref={refs.previousBtn}
                  onClick={this.onPreviousClick}
                >
                  Previous
                </PreviousParticleBtn>
                <BackToMapBtn onClick={this.resetCamera}>
                  Back to map
                </BackToMapBtn>
                <NextParticleBtn ref={refs.nextBtn} onClick={this.onNextClick}>
                  Next
                </NextParticleBtn>
              </NavUI>

              {!isMobile && dataType === 'Audio' && (
                <AudioToggleButton onClick={this.handleAudioToggle}>
                  Toggle audio <AudioToggleIcon active={audioIsActive} />
                </AudioToggleButton>
              )}
            </>
          )}

          {labels &&
            this.interactive &&
            React.Children.toArray(
              labels.map((label, labelIndex) => (
                <MapLabel
                  ref={(ref) => (refs.labels[labelIndex] = ref)}
                  color={label.Color}
                  text={label.Label}
                  interactive={interactive}
                >
                  {label.Label}
                </MapLabel>
              )),
            )}

          {loading && (
            <MapLoading>
              <Heading3>Loading...</Heading3>
            </MapLoading>
          )}

          <MapPrompt
            activeParticle={!this.interactive || this.state.activeParticle}
          >
            <TextBodySmall>Click the dots to explore each sample</TextBodySmall>
          </MapPrompt>
        </MapMain>
      </>
    );
  }
}

Map.contextType = ScreenContext;

export default Map;
